import React from "react";

export const Footer = () => {
  return (
    <footer>
      <div className="links">
        <div className="useful">
          <h3>Useful Links</h3>
          <div><a href="http://www.bioinf.org.uk/" target="_blank" rel="noopener noreferrer">The ACRM Group</a></div>
          <div><a href="https://github.com/samirelanduk/ZincBindWeb" target="_blank" rel="noopener noreferrer">Source code</a></div>
          <div> <a href="https://docs.api.zincbind.net" target="_blank">API Docs</a> | <a href="https://docs.predict.zincbind.net" target="_blank">Predict Docs</a></div>
        </div>
        <div className="related">
          <h3>Related Projects</h3>
          <div><a href="http://metalweb.cerm.unifi.it/" target="_blank" rel="noopener noreferrer">MetalPDB</a></div>
          <div><a href="http://synpharm.guidetopharmacology.org/" target="_blank" rel="noopener noreferrer">SynPHARM</a></div>
          <div><a href="https://www.bindingdb.org/bind/index.jsp" target="_blank" rel="noopener noreferrer">The Binding Database</a></div>
        </div>
      </div>
      <div className="attribution">
        Created by: <a target="_blank" rel="noopener noreferrer" href="https://samireland.com/">Sam Ireland</a>
      </div>
    </footer>
  );
}
 
export default Footer;